import { NetworkAuth } from '@/services/axios'

export default {
	CheckDuplicate(project, token) {
		return NetworkAuth.get(`${process.env.REACT_APP_NETWORK_HOST}/network/check/${project}?token=${token}`)
	},
	ProcessDuplicate(project, data, token) {
		return NetworkAuth.post(`${process.env.REACT_APP_NETWORK_HOST}/network/duplicate/${project}?token=${token}`, data)
	},
	UpdateTransaction(project, body) {
		return NetworkAuth.put(`${process.env.REACT_APP_NETWORK_HOST}/network/transaction/${project}`, body)
	},
	UpdateBedrockdaoWallet(project, body) {
		return NetworkAuth.put(`${process.env.REACT_APP_NETWORK_HOST}/network/bedrockdao/wallet/${project}`, body)
	},
}
