import SignupLayout from '../../components/layout/SignupLayout'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import PublicGuard from '@/guard/public'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ProjectService from '@/services/projects'

export default function SignupPage(props) {
	const [project] = useState(window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false)
	const router = useHistory()
	const [ready, setReady] = useState(false)
	const [settings, setSettings] = useState(null)

	useEffect(async () => {
		await ProjectService.Settings(project)
			.then((data) => {
				setSettings(data)
				setReady(true)
			})
			.catch((err) => {
				console.log('err')
				console.log(err)
				router.push('/network')
			})
	}, [])

	return (
		<PublicGuard>
			<GoogleReCaptchaProvider
				reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
				{ready ? <SignupLayout settings={settings}>{props.children}</SignupLayout> : null}
			</GoogleReCaptchaProvider>
		</PublicGuard>
	)
}
