import { useEffect, useState, useContext } from 'react'
import {
	faCheckCircle,
} from '@arcane-ui/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@arcane-ui/react-fontawesome'
import Button from '@/components/ui/Button'

import SubNav from '../menu/SubNav'
import Footer from '../menu/Footer'
import Hooks from '@/components/tool/Hooks'
import SynapsClient from '@/lib/synaps'
import {
	faPassport,
} from '@arcane-ui/pro-duotone-svg-icons'
import { AuthContext } from '@/context/auth'
import { useLocation } from 'react-router-dom'
import SessionService from '@/services/session'
import DuplicateOptions from '@/components/user/DuplicateData'
import Cookie from "js-cookie";
import AuthService from '@/services/auth'


export default function UbisoftPage(props) {
	const width = Hooks()
	const [authState, authDispatch] = useContext(AuthContext)
	const [project] = useState(window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false)
	const [open, setOpen] = useState(false)
	const [selected, setSelected] = useState("")
	const [duplicateOptions, setDuplicateOptions] = useState(null)
	const [duplicateInited, setDuplicateInited] = useState(false)
	const location = useLocation()
	const [isInit, setIsInit] = useState(false)
	const [clicked, setClicked] = useState(false)
	const [loading, setLoading] = useState(false)
	const [sessionStatus, setSessionStatus] = useState('PENDING')

	function InitSynaps(session, color, jwt) {
		setIsInit(false)
		let colorSettings = {
			primary: color,
			secondary: 'FFFFFF'
		}

		console.log("isInit")
		console.log(isInit)

		if (jwt === false) {
			const Synaps = new SynapsClient(session, authState.user.type.toLowerCase())
			Synaps.init({
				type: 'embed',
				colors: colorSettings,
			})
		}

		const SynapsModal = new SynapsClient(session, authState.user.type.toLowerCase())
		SynapsModal.init({
			type: 'modal',
			colors: colorSettings,
		})

		SynapsModal.on('finish', () => {
			console.log("FINISH EVENT")
			var payload = {
				type: 'finish',
				data: {},
			}
			window.parent.postMessage(payload, '*')
		})

		setIsInit(true)
	}


	useEffect(async () => {
		await setSessionStatus(props.data.session_status)
		if (authState.settings.color !== '') {
			await InitSynaps(authState.user.session_id, authState.settings.color, authState.settings.jwt)
		} else {
			await InitSynaps(authState.user.session_id, '000000', authState.settings.jwt)
		}
		return () => { }
	}, [])

	useEffect(async () => {
		if (width < 768 && isInit === true && authState.user.first_auth === 'NOT_ACTIVATED' && open === false && clicked === false) {
			setClicked(true)
			let button = document.getElementById('synaps-btn')
			button.click()
		}
	}, [width, isInit])

	function GoToStaking() {
		window.open('https://account.aleph.im', '_blank');
	}

	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className='max-w-7xl mx-auto relative'>
					<div className='dashboard-container'>
						<div className='md:flex mx-auto md:justify-between mt-3'>
							<div className={`${width >= 768 ? 'block' : 'hidden'} mt-5 mr-5 ml-5 modal-place md:w-1/2`}>
								<div className='synaps-modal modal-background rounded-xl bg-white p-4' id="synaps-embed"></div>
							</div>

							<div className={`${width >= 768 ? 'hidden' : 'block'} mt-5 text-center mx-auto bg-white mr-5 ml-5 rounded-xl p-4 shadow md:w-1/2`}>
								<FontAwesomeIcon
									className={'text-gray-500 text-3xl mx-auto mt-3 text-3xl mt-0.5'}
									icon={faPassport}></FontAwesomeIcon>
								<p className="mt-1">Click on the following button to start verification process</p>
								<button type="submit" id="synaps-btn"
									className="mx-auto my-2 mx-auto mt-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-400 focus:outline-none focus-primary-btn active:bg-primary-700 transition duration-150 ease-in-out">Verify my identity
								</button>
							</div>
						
							{authState.settings !== undefined && authState.settings !== null  ? <div className="md:w-1/2 mx-auto">
								<div className='bg-white rounded-xl p-6 shadow verification-steps text-network'>
									<h3 className="text-2xl text-primary-800 mb-1 font-bold">Ubisoft would like to verify your identity through its partner Synaps&apos; KYC solution</h3>
									<div className="flex mt-5">
										<div>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											Start your verification now and confirm your delegation on the Ubisoft node !
										</div>
									</div>

									<div className="flex mt-5">
										<div className='mt-3'>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											<p>Your document will be verified by Synaps&apos; agents.</p>
											<p>(It can take up to 24 hours during peak traffic times.)</p>
										</div>
									</div>

									<div className="flex mt-5">
										<div>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											Once every step has been <span className="text-green-500">Verified</span>, you receive a validation e-mail.
										</div>
									</div>


									{sessionStatus === "FINISHED" ?
										<div className="flex mt-5">
											<div>
												<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-4" /></p>
											</div>
											<div>
												<Button
													type='submit'
													full
													onClick={() => GoToStaking()}
													loading={loading}
													label='Start staking now'
												/>
											</div>
										</div> : null}
								</div>



								<div className='rounded-xl p-6 shadow mt-6 border-anima text-network'>
									<p className="text-xl text-primary-800 font-bold">You&apos;ve dreamed of getting rid of KYC for years ?</p>
									<div className='flex'>
										<p className="text-xl text-primary-800 mb-1 font-bold">Anima is your savior and will reward you in addition </p>
										<img
											className='h-6 ml-2 cursor-pointer transition duration-200 ease-in'
											src='https://storage.googleapis.com/synaps-static-assets/anima_logo.png'></img>
									</div>
									<div className="flex mt-5">
										<div>
											Anima allows you to decentralize your identity for reuse. It is secured by encryption with your favorite crypto wallet.
										</div>
									</div>

									<div className="flex mt-5">
										<div>
											Just so you know, Anima owners will be rewarded at token launch for
											<li>
												Creating their Anima after verification process
											</li>
											<li>
												Using their Anima to skip KYC process
											</li>
										</div>
									</div>
								</div>

							</div> : null}
						</div>
					</div>
					<Footer></Footer>
				</div>
			</div>
		</div >
	)
}
