import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import LoginPage from '@/pages/project/login'
import SignupPage from '@/pages/project/signup'
import NotFoundPage from '@/pages/not-found'
import Network from '@/pages/network'
import Dashboard from '@/pages/project/dashboard'
import { ToastContainer } from 'react-toastify'
import { AuthContextProvider } from '@/context/auth'
import { useEffect, useState, useContext } from "react";
import Cookie from "js-cookie";
import { AuthContext } from '@/context/auth'
import { useHistory } from "react-router-dom";
import AuthService from '@/services/auth'

function AuthCore(props) {
	const [, authDispatch] = useContext(AuthContext);
	const [ready, setReady] = useState(false);
	const router = useHistory();
	const [project] = useState(window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false)

	useEffect(async () => {
		const s_auth = Cookie.get("s_auth");
		if (s_auth == "" || s_auth == null) {
			const query = new URLSearchParams(location.search)
			const tokenParam = query.get('token')
			const wallet = query.get('wallet')
			const email = query.get('email')
			const type = query.get('type')
			let redirectURI = '/signup?'
			if (tokenParam !== null) {
				redirectURI = '/signup?token=' + tokenParam
			}
			if (wallet !== null) {
				redirectURI = redirectURI + 'wallet=' + wallet + '&'
			}
			if (email !== null) {
				redirectURI = redirectURI + 'email=' + email + '&'
			}
			if (type !== null) {
				redirectURI = redirectURI + 'type=' + type + '&'
			}

			router.push(redirectURI);
			setReady(true);
			return;
		}
		await AuthService.Me(project.toString())
			.then(async (data) => {
				const auth = {
					user: {
						email: data.email,
						code: '',
						type: data.account_type,
						project: data.project,
						session_id: data.session_id,
						access: data.access_token,
					},
					settings: data.settings,
					authenticated: true,
				}

				await authDispatch({
					type: 'SET_AUTH',
					payload: auth,
				})

				Cookie.set(
					"s_auth",
					Buffer.from(JSON.stringify(data.access_token)).toString("base64")
				);
				setReady(true);
			})
			.catch(() => {
			})
		return () => { }

	}, []);

	return ready ? props.children : null;
}

function App() {
	return (
		<AuthContextProvider>
			<Router>
				<AuthCore>
					<Switch>
						<Route exact path='/' component={Dashboard} />
						<Route exact path='/network' component={Network} />
						<Route exact path='/login' component={LoginPage} />
						<Route exact path='/signup' component={SignupPage} />
						<Route exact path='/network' component={Network} />
						<Route path='*' component={NotFoundPage} />
					</Switch>
				</AuthCore>
			</Router>
			<ToastContainer
				position='top-right'
				autoClose={4000}
				hideProgressBar={false}
				newestOnTop
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</AuthContextProvider>
	)
}

export default App
