import axios from "axios";
import Cookies from "js-cookie";
import { b64Decode } from "@/lib/b64";

const NetworkAuth = axios.create();
NetworkAuth.interceptors.request.use((request) => {
  let s_auth = Cookies.get("s_auth");
  s_auth = JSON.parse(b64Decode(s_auth));
  const access_token = s_auth;
  request.headers["Authorization"] = access_token;
  return request;
});

NetworkAuth.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401) {
      Cookies.remove("s_auth");
    	window.location.href = `/login`;
      return Promise.reject({
        api_code: "AUTH_SESSION_EXPIRED",
        message: "Session expired",
      });
    }
    if (error.response === undefined) {
      return Promise.reject({
        api_code: "NETWORK_ERROR",
        message: "Network Error",
      });
    }

    return Promise.reject(error.response.data);
  }
);

const NetworkPublic = axios.create();

NetworkPublic.interceptors.request.use((request) => {
  return request;
});

NetworkPublic.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response === undefined) {
      return Promise.reject({
        api_code: "NETWORK_ERROR",
        message: "Network Error",
      });
    }
    return Promise.reject(error.response.data);
  }
);

export { NetworkAuth, NetworkPublic };
