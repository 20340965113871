import { AuthContext } from '@/context/auth'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import InitSignup from '@/components/auth/InitSignup'
import ProjectService from '@/services/projects'

export default function Signup(props) {
	const [authState, authDispatch] = useContext(AuthContext)
	const router = useHistory()
	const [type, setType] = useState("KYC")
	const [project] = useState(window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false)
	const [ready, setReady] = useState(false)
	const [settings, setSettings] = useState(null)

	useEffect(async () => {
		authDispatch({
			type: 'SET_AUTH',
			payload: {
				currentComponent: <InitSignup></InitSignup>,
			},
		})

		const KYBProjects = [
			"cantina-royale-corporate",
			"matrixportventurefund",
			"jumpcrypto",
			"moonpay",
			"safelaunch-kyb",
			"oursong-clients",
			"ashswap-corporate",
			"legacynetwork-corporate",
			"hatom-corporate",
			"efilplus",
			"polygon-edd-corporate",
			"entity-corporate",
			"voodooinfinity",
			"ramper",
			"dewhales",
			"partisiablockchain",
		]

		if (KYBProjects.includes(project.toString()) === true) {
			setType("KYB")
		}

		await ProjectService.Settings(project)
			.then((data) => {
				setSettings(data)
				setReady(true)
			})
			.catch((err) => {
				console.log('err')
				console.log(err)
				router.push('/network')
			})
	}, [])



	return (
		<>
			<div className='min-h-screen px-8 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
				<div className='mx-auto mb-5'>
					<img
						className={`${props.settings.auth.logo_size} rounded-xl`}
						src={props.settings.auth.logo}></img>
				</div>
				<div>
					<div className='mt-5 sm:mx-auto sm:w-full sm:max-w-sm bg-white rounded-xl p-4 shadow'>
						{authState.currentComponent}
					</div>
				</div>
				{ready && !settings.jwt ? <>
					<div className="mx-auto mt-5">
						<a onClick={() => router.push('/login')} className="text-primary-500 font-bold hover:underline cursor-pointer">Login for the {type} process</a>
					</div>
				</> : null}
			</div>
		</>
	)
}
