import MiniButton from '@/components/ui/MiniButton'
import { faAngleRight } from '@arcane-ui/pro-solid-svg-icons'

export default function NotFound() {

	function goHome() {
		window.location.href = 'https://synaps.io/'
	}

	return (
		<div className='min-h-screen px-8 bg-primary-500 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
			<div className="flex justify-center items-center">
				<div className="mr-8">
					<img
						className='h-100px'
						src='/img/logo/synaps-logo-s.svg'></img>
				</div>
				<div className="ml-6">
					<h2 className="font-bold text-3xl text-white">Synaps Network plateform</h2>
					<div className="mt-4">
						<MiniButton icon={faAngleRight} label="Go to Synaps Website" currentBg="primary" theme="secondary" onClick={() => goHome()} />
					</div>
				</div>
			</div>
		</div>
	)
}
