import AuthGuard from '@/guard/auth'
import Navbar from '@/components/menu/Navbar'
import DashbordPage from '@/components/pages/DashboardPage'
import RadomPage from '@/components/pages/RadomPage'
import ArtCanDiePage from '@/components/pages/ArtCanDiePage'
import UbisoftPage from '@/components/pages/UbisoftPage'
import BedrockdaoPage from '@/components/pages/BedrockdaoPage'
import { useEffect, useState, useContext } from 'react'
import { AuthContext } from '@/context/auth'
import AuthService from '@/services/auth'
import Cookie from "js-cookie";

function Dashboard() {
	const [authState, authDispatch] = useContext(AuthContext)
	const [project] = useState(window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false)
	const [ready, setReady] = useState(false)
	const [client, setClient] = useState("")
	const [data, setData] = useState(null)
	const query = new URLSearchParams(location.search)
	const [radomStatus, setRadomStatus] = useState(query.get('radom_status'))

	const nonCommonFlow = [
		"artcandie",
		"ubisoft",
		"bedrock",
		"efilplus"
	]

	const radomClients = [
		"efilplus"
	]

	useEffect(async () => {
		await AuthService.Me(project.toString())
			.then(async (data) => {
				const auth = {
					user: {
						email: data.email,
						code: '',
						type: data.account_type,
						project: data.project,
						session_id: data.session_id,
						access: data.access_token,
					},
					settings: data.settings,
					authenticated: true,
				}

				authDispatch({
					type: 'SET_AUTH',
					payload: auth,
				})
				
				Cookie.set(
					"s_auth",
					Buffer.from(JSON.stringify(data.access_token)).toString("base64")
				);
				setData(data)
				setClient(project.toString())
				if (radomStatus === "success" || data.radom_status === "success") {
					setClient("basic")
				}

				setReady(true)
			})
			.catch((err) => {
				console.log(err)
			})
		return () => { }
	}, [])

	return (
		<AuthGuard>
			{ready ? 
			<div className='relative min-h-screen flex flex-col'>
				<Navbar></Navbar>
				{!nonCommonFlow.includes(client) ? 
					<DashbordPage data={data}></DashbordPage>
					: 
					<>
					{radomClients.includes(client) ?
						<RadomPage data={data}></RadomPage> : null}
					{client === "artcandie" ?
						<ArtCanDiePage data={data}></ArtCanDiePage> : null}
					{client === "ubisoft" ?
						<UbisoftPage data={data}></UbisoftPage> : null}
					{client === "bedrock" ?
						<BedrockdaoPage data={data}></BedrockdaoPage> : null}
					</>
				}
			</div>
			: null}
		</AuthGuard>
	)
}

export default Dashboard
