import { useEffect, useState, useContext } from 'react'
import {
	faCheckCircle, faMinus, faPlus, faWallet,
} from '@arcane-ui/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@arcane-ui/react-fontawesome'

import SubNav from '../menu/SubNav'
import Footer from '../menu/Footer'
import Hooks from '@/components/tool/Hooks'
import SynapsClient from '@/lib/synaps'
import {
	faPassport,
} from '@arcane-ui/pro-duotone-svg-icons'
import { AuthContext } from '@/context/auth'
import SessionService from '@/services/session'
import ButtonIcon from '@/components/ui/ButtonIcon'
import Input from '@/components/ui/Input'
import Alert from '@/components/ui/Alert'


export default function BedrockdaoPage(props) {
	const width = Hooks()
	const [authState, authDispatch] = useContext(AuthContext)
	const [project] = useState(window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false)
	const [open, setOpen] = useState(false)
	const [selected, setSelected] = useState("")
	const [duplicateOptions, setDuplicateOptions] = useState(null)
	const [isInit, setIsInit] = useState(false)
	const [clicked, setClicked] = useState(false)
	const [walletList, setWalletList] = useState([])
	const [ready, setReady] = useState(false)
	const [wallet, setWallet] = useState("")
	const [errorMessage, setErrorMessage] = useState("")


	function InitSynaps(session, color, jwt) {
		setIsInit(false)
		let colorSettings = {
			primary: color,
			secondary: 'FFFFFF'
		}

		if (jwt === false) {
			const Synaps = new SynapsClient(session, authState.user.type.toLowerCase())
			Synaps.init({
				type: 'embed',
				colors: colorSettings,
			})
		}

		const SynapsModal = new SynapsClient(session, authState.user.type.toLowerCase())
		SynapsModal.init({
			type: 'modal',
			colors: colorSettings,
		})

		SynapsModal.on('finish', () => {
			console.log("FINISH EVENT")
			var payload = {
				type: 'finish',
				data: {},
			}
			window.parent.postMessage(payload, '*')
		})

		setIsInit(true)
	}

	useEffect(async () => {
		if (props.data.bedrockdao_wallets.length > 0) {
			let wallets = []
			props.data.bedrockdao_wallets.forEach(element => wallets.push(element));
			setWalletList(wallets)
		}
		if (authState.settings.color !== '') {
			InitSynaps(authState.user.session_id, authState.settings.color, authState.settings.jwt)
		} else {
			InitSynaps(authState.user.session_id, '000000', authState.settings.jwt)
		}
	}, [])

	useEffect(async () => {
		if (width < 768 && isInit === true && authState.user.first_auth === 'NOT_ACTIVATED' && open === false && clicked === false) {
			setClicked(true)
			let button = document.getElementById('synaps-btn')
			button.click()
		}
	}, [width, isInit])

	async function IsValidAddress(address) {
        if (!/^0x[0-9a-fA-F]{40}$/.test(address)) {
            return false;
        }
        return true;
    }

	async function UpdateBedrockdaoWallet() {
		let body = {
			"wallets": walletList
		}
		await SessionService.UpdateBedrockdaoWallet(project.toString(), body)
		.then(async (data) => {
			console.log("SUCCESS")
		})
		.catch((err) => {
			console.log(err)
		})
		return () => { }
    }

	async function AddWallet() {
		setErrorMessage("")
		if (walletList.length >= 5) {
			setErrorMessage("Wallet limit reached")
			return
		}

		if (!await IsValidAddress(wallet)) {
			setErrorMessage("Invalid wallet address")
			return
		}
        setReady(false)
        let newList = walletList
        await newList.push(wallet)
        setWalletList(newList)
        setWallet("")
		await UpdateBedrockdaoWallet()
        setReady(true)
    }

    async function RemoveWallet(index) {
		setErrorMessage("")
        setReady(false)
        let newList = walletList
        await newList.splice(index, 1);
        setWalletList(newList)
		await UpdateBedrockdaoWallet()
        setReady(true)
    }

	return (
		<div>
			<div>
				<SubNav></SubNav>
				<div className='max-w-7xl mx-auto relative'>
					<div className='dashboard-container'>
						<div className='md:flex mx-auto md:justify-between mt-3'>
							<div className={`${width >= 768 ? 'block' : 'hidden'} mt-5 mr-5 ml-5 modal-place md:w-1/2`}>
								<div className='synaps-modal modal-background rounded-xl bg-white p-4' id="synaps-embed"></div>
							</div>

							<div className={`${width >= 768 ? 'hidden' : 'block'} mt-5 text-center mx-auto bg-white mr-5 ml-5 rounded-xl p-4 shadow md:w-1/2`}>
								<FontAwesomeIcon
									className={'text-gray-500 text-3xl mx-auto mt-3 text-3xl mt-0.5'}
									icon={faPassport}></FontAwesomeIcon>
								<p className="mt-1">Click on the following button to start verification process</p>
								<button type="submit" id="synaps-btn"
									className="mx-auto my-2 mx-auto mt-2 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary-500 hover:bg-primary-400 focus:outline-none focus-primary-btn active:bg-primary-700 transition duration-150 ease-in-out">Verify my identity
								</button>
							</div>

							{authState.settings !== undefined && authState.settings !== null  ? <div className="md:w-1/2 mx-auto">
								<div className='bg-white rounded-xl p-6 shadow verification-steps text-network'>
									<h3 className="text-2xl text-primary-800 mb-1 font-bold">Start your verification process</h3>
									<div className="flex mt-5">
										<div>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											Complete each verification step
										</div>
									</div>

									<div className="flex mt-5">
										<div className='mt-3'>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											<p>Your document will be verified by our agents.</p>
											<p>(It can take up to 24 hours during peak traffic times.)</p>
										</div>
									</div>

									<div className="flex mt-5">
										<div>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											You will receive an e-mail notification if any document(s) are <span className="text-red-500">Rejected</span>
										</div>
									</div>

									<div className="flex mt-5">
										<div>
											<p><FontAwesomeIcon icon={faCheckCircle} className="text-green-500 mr-3 mt-0.5" /></p>
										</div>
										<div>
											Once every step has been <span className="text-green-500">Verified</span>, you receive a validation e-mail.
										</div>
									</div>

								</div>

								<div className='rounded-xl p-6 shadow mt-6 border-anima text-network'>
									<p className="text-xl text-primary-800 font-bold">Please submit your wallet(s) addresses:</p>
									<div className='w-full mt-4'>
                                                    {walletList.length > 0 ? <p className="block text-sm font-medium text-primary-700">Current wallet list :</p> : <p className="block text-sm font-medium text-primary-700 mt-2">Please add at least one wallet and click on the <strong>+</strong></p>}
                                                    {walletList.map((wallet, index) => {
                                                        return (
                                                            <div key={index} className='flex mt-1'>
                                                                <div className='w-full mr-3 mt-1'>
                                                                    <Input
                                                                        name='wallet'
                                                                        icon={faWallet}
                                                                        value={wallet}
                                                                        disabled
                                                                    />
                                                                </div>
                                                                <div className='ml-3'>
                                                                    <ButtonIcon
                                                                        className={"p-0 m-0"}
                                                                        onClick={() => RemoveWallet(index)}
                                                                        theme={"error"}
                                                                        icon={faMinus}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
													{errorMessage !== "" ? <div className='mt-2'><Alert type='error' title={errorMessage}></Alert></div> : null}
                                                    <div className='flex mt-3'>
                                                        <div className='w-full mr-3'>
                                                            <Input
                                                                label={"Add new wallet address :"}
                                                                setValue={(e) => setWallet(e)}
                                                                placeholder='0x0000000000000000000000000000000000'
                                                                name='wallet'
                                                                icon={faWallet}
                                                                value={wallet}
                                                            />
                                                        </div>
                                                        <div className='ml-3 mt-6'>
                                                            <ButtonIcon
                                                                className={"p-0 m-0"}
                                                                onClick={() => AddWallet()}
                                                                theme={"success"}
                                                                disabled={wallet === ""}
                                                                icon={faPlus}
                                                            />
                                                        </div>
                                                    </div>
									</div>
								</div>

							</div> : null}
						</div>
					</div>
					<Footer></Footer>
				</div>
			</div>
		</div >
	)
}
