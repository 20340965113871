import { AuthContext } from '@/context/auth'
import { useContext, useState, useEffect } from 'react'
import Alert from '../ui/Alert'
import Label from '../ui/Label'
import ActionButton from '../ui/ActionButton'
import {
	faExclamationCircle,
	faPaperPlane,
	faRedoAlt,
} from '@arcane-ui/pro-duotone-svg-icons'
import { faFingerprint } from '@arcane-ui/pro-solid-svg-icons'
import AuthService from '@/services/auth'
import InputDigits from '../ui/InputDigits'
import Button from '../ui/Button'
import Sleep from '@/lib/sleep'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useHistory, useLocation } from 'react-router-dom'
import Cookie from "js-cookie";

export default function AuthCode() {
	const [errorMessage, setErrorMessage] = useState('Error')
	const [error, setError] = useState(null)
	const { executeRecaptcha } = useGoogleReCaptcha()
	const statusList = {
		default: {
			type: 'default',
			icon: faRedoAlt,
			message: 'Resend auth code',
		},
		error: {
			type: 'error',
			icon: faExclamationCircle,
			message: errorMessage,
		},
		success: {
			type: 'success',
			icon: faPaperPlane,
			message: 'Email sent',
		},
	}
	const [authState, authDispatch] = useContext(AuthContext)
	const [loading, setLoading] = useState(false)
	const [resendLoading, setResendLoading] = useState(false)
	const [status, setStatus] = useState(statusList['default'])
	const [authCode, setAuthCode] = useState('')
	const [project] = useState(window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false)
	const router = useHistory()
	const location = useLocation()
	const [token, setToken] = useState('')
	const [captchaReady, setCaptchaReady] = useState(false)
	const [ready, setReady] = useState(false)

	function resetAction() {
		setStatus(statusList['default'])
	}

	async function resendCode() {
		setResendLoading(true)
		setError(null)
		const body = {
			email: authState.user.email,
			type: authState.user.type,
			project: project.toString(),
		}

		const recaptchaToken = await executeRecaptcha('login_page')

		const config = {
			headers: {
				Recaptcha: recaptchaToken,
			},
		}
		await Sleep(1000)

		await AuthService.Init(body, config)
			.then(() => {
				setResendLoading(false)
				setStatus(statusList['success'])
			})
			.catch((err) => {
				setResendLoading(false)
				setErrorMessage(err.message)
				setStatus(statusList['error'])
			})
		return () => { }
	}

	useEffect(async () => {
		const query = await new URLSearchParams(location.search)
		setToken(query.get('token'))
	})

	async function Login() {
		const body = {
			email: authState.user.email,
			code: authCode,
			type: authState.user.type,
			project: project.toString(),
		}

		setLoading(true)

		const recaptchaToken = await executeRecaptcha('login_page')

		const config = {
			headers: {
				Recaptcha: recaptchaToken,
			},
		}

		await Sleep(500)

		await AuthService.Login(body, config)
			.then((data) => {
				setLoading(false)

				const auth = {
					user: {
						email: body.email,
						code: '',
						type: body.type,
						project: project.toString(),
						session_id: data.session_id,
						access: data.access_token,
					},
					settings: data.settings,
					authenticated: true,
				}

				authDispatch({
					type: 'SET_AUTH',
					payload: auth,
				})

				Cookie.set(
					"s_auth",
					Buffer.from(JSON.stringify(data.access_token)).toString("base64")
				);

				router.push('/?token=' + token)
			})
			.catch((err) => {
				setAuthCode('')
				setLoading(false)
				setError(err.message)
			})
		return () => { }
	}

	useEffect(async () => {
		if (executeRecaptcha) {
			setCaptchaReady(true)
		}
	}, [executeRecaptcha])

	useEffect(async () => {
		if (captchaReady) {
			setReady(true)
		}
	}, [captchaReady])

	useEffect(async () => {
		if (ready) {
			setLoading(false)
		} else {
			setLoading(true)
		}
	}, [ready])

	return (
		<><div>
			{ready ? <>
				<div>
					{error ? (
						<Alert type="error" title={error}></Alert>
					) : (
						<Alert
							type="email"
							title="Check your inbox"
							description={'Auth code has been sent to your email'}></Alert>
					)}
					<div className="flex justify-between">
						<div>
							<Label value="Auth Code"></Label>
						</div>
						<div>
							<Label font="light" value={authState.user?.email.length > 24 ? authState.user?.email.slice(0, 24) + '...' : authState.user?.email}></Label>
							{!loading ? (
								<ActionButton
									status={status}
									onClick={resendCode}
									onFinish={resetAction}
									loading={resendLoading}></ActionButton>
							) : null}
						</div>
					</div>
				</div>
				<div className="mt-3">
					<InputDigits value={[authCode, setAuthCode]} />
				</div>
			</> : null}
			<div className="mt-4">
				<Button
					onClick={Login}
					full
					icon={faFingerprint}
					disabled={authCode.trim().length != 6}
					loading={loading}
					label="Authenticate"
				/>
			</div>
		</div>
		</>
	)
}
