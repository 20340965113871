import { FontAwesomeIcon } from '@arcane-ui/react-fontawesome'

export default function CardTitle(props) {
	return (
		<h1 className="font-bold px-6 py-4 pb-1">
			<span className="mr-2.5 text-bluegray-500">
				<FontAwesomeIcon icon={props.icon}></FontAwesomeIcon>
			</span>
			<span className="text-primary-500">{props.value}</span>
		</h1>
	)
}
