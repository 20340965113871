import { Redirect } from 'react-router-dom'
import { useContext, useState, useEffect } from 'react'
import { AuthContext } from '@/context/auth'

export default function AuthGuard({ children }) {
	const [auth] = useContext(AuthContext)
	const [ready, setReady] = useState(false)
	const [token, setToken] = useState('')

	useEffect(() => {
		const query = new URLSearchParams(location.search)
		const tokenParam = query.get('token')
		setToken(tokenParam)
		setReady(true)
	}, [])


	let redirectURI = '/signup?token=' + token

	return ready ? <>
		{auth.authenticated ? children : <Redirect to={redirectURI} />}
	</> : null
}
