import { AuthContext } from '@/context/auth'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import InitLogin from '@/components/auth/Init'

export default function Login(props) {
	const [authState, authDispatch] = useContext(AuthContext)
	const router = useHistory()
	const [type, setType] = useState("KYC")
	const [project] = useState(window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false)

	const KYBProjects = [
		"cantina-royale-corporate",
		"matrixportventurefund",
		"jumpcrypto",
		"moonpay",
		"safelaunch-kyb",
		"oursong-clients",
		"ashswap-corporate",
		"legacynetwork-corporate",
		"hatom-corporate",
		"efilplus",
		"polygon-edd-corporate",
		"entity-corporate",
		"voodooinfinity",
		"ramper",
		"dewhales",
		"partisiablockchain",
	]

	useEffect(async () => {
		authDispatch({
			type: 'SET_AUTH',
			payload: {
				currentComponent: <InitLogin></InitLogin>,
			},
		})

		if (KYBProjects.includes(project.toString()) === true) {
			setType("KYB")
		}
	}, [])

	return (
		<>
			<div className='min-h-screen px-8 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
				<div className='mx-auto mb-5'>
					<img
						className={`${props.settings.auth.logo_size} rounded-xl`}
						src={props.settings.auth.logo}></img>
				</div>
				<div>
					<div className='mt-5 sm:mx-auto sm:w-full sm:max-w-sm bg-white rounded-xl p-4 shadow'>
						{authState.currentComponent}
					</div>
				</div>
				<div className="mx-auto mt-5">
					<a onClick={() => router.push('/signup')} className="text-primary-500 font-bold hover:underline cursor-pointer">Register for the {type} process</a>
				</div>
			</div>
		</>
	)
}
